<template>
    <div>
        <div class="top">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                    <div class="top-item">
                        <div class="title">
                            快速选择
                        </div>
                        <div class="data" v-for="(item,indey) in pickerOptions.shortcuts"
                             :class="value2.toString()==item.onClick('kk').map(itey => parseTime(itey, '{y}-{m}-{d}')).toString()?'hover':''"
                             :key="item.text" @click="clicktime(item,indey)">{{ item.text }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <div class="top-item">
                        <div class="title">
                            选择时间
                        </div>
                        <el-date-picker value-format="yyyy-MM-dd" @change="datack" v-model="value2" type="daterange"
                                        align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期" :picker-options="pickerOptions">
                        </el-date-picker>
                       
                    </div>

                </el-col>

            </el-row>
            <div class="devList">
                <span class="devListbox" :class="iot_id ===''?'active':''" @click="tab_iot_id('')"> 全部机器</span>
                <span class="devListbox" :class="iot_id ===item.id?'active':''" @click="tab_iot_id(item.id)"
                      :key="index" v-for="(item,index) in devList">{{ item.nick_name }}</span>
                <!-- <div v-for="(item) in devList">{{item.nick_name}} </div> -->
            </div>
        </div>
        <div class="content " style="width: 100%;">
            <el-table :data="reportList" border style="width: 100%;margin-top: 20px;">
                <el-table-column prop="order_name" label="工作内容" width="300">
                    <template slot-scope="{row}">
                        {{
                            row.type === 1 ? row.order_name + '-' + row.order_item_name + '-' + row.order_process_name : row.work_content
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="allot_num" label="分配数量"></el-table-column>
                <el-table-column prop="complete_num" label="完成数量"></el-table-column>
                <el-table-column prop="complete_num" label="进度">
                    <template slot-scope="{row}">
                        {{ row.allot_num > 0 ? (row.complete_num / row.allot_num * 100).toFixed(0) + '%' : '' }}
                    </template>
                </el-table-column>
                  <el-table-column prop="staff.name" label="员工"></el-table-column>
                  <el-table-column prop="device_name" label="机器"></el-table-column>
                  <el-table-column prop="created_at" label="下发时间"></el-table-column>
                  <el-table-column prop="plan_complete_time" label="计划完成时间"></el-table-column>
                  <el-table-column prop="reality_start_time" label="实际开始时间"></el-table-column>
                  <el-table-column prop="reality_complete_time" label="实际完成时间"></el-table-column>
                  <el-table-column prop="work_time" label="实际用时"></el-table-column>
                  <el-table-column prop="device_time" label="机器用时"></el-table-column>
                </el-table>
            <div class="block" style="    margin-top: 10px;">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                               :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                               layout="sizes, prev, pager, next" :total="total">
                </el-pagination>

            </div>
        </div>
    </div>
</template>

<script>

import {parseTime} from '@/utlis/date.js';
import {deviceList} from "@/service/api";
import {getcompany} from "@/service/lib/localToken";
import {getStaffTasks} from "../../service/statistics";

export default {
    data() {
        return {
            iot_id: '',
            parseTime,
            index: -1,
            isitem: false,
            value2: [],
            pickerOptions: {
                shortcuts: [{
                    text: '三天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);

                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }

                    }
                }, {
                    text: '一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }, {
                    text: '半个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }, {
                    text: '一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }]
            },
            echartsOne: null,
            echartsTwo: null,
            echartThree: null,
            params: {
                start_date: '',
                end_date: ''
            },
            devList: [],
            page: 1,
            limit: 10,
            start_date: '',
            end_date: '',
            reportList: [],
            total: 0
        };
    },
    methods: {
      

  
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getreport();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getreport();
        },
        getTimes(item) {
            return (new Date(item).getTime());
        },
        tab_iot_id(id) {
            this.iot_id = id;
            this.getreport();
        },
        getdeviceList() {
            deviceList({limit: 99, page: 1}).then(res => {

                this.devList = res.data.list;

            });
        },
        datack(time) {
            console.log(time);
            this.start_date = time[0];
            this.end_date = time[1];
            this.getreport();
        },
        clicktime(item) {
            this.value2 = item.onClick(this).map(item => parseTime(item, '{y}-{m}-{d}'));
            console.log(this.value2);
            this.start_date = this.value2[0];
            this.end_date = this.value2[1];
            this.getreport();

        },
        getreport() {
            let data = {
                limit: this.limit,
                page: this.page,
                device_id: this.iot_id,
                accept_time_start: this.start_date,
                accept_time_end: this.end_date
            };
            getStaffTasks(data).then(res => {
                this.reportList = res.data.list;
                this.total = res.data.total;
            });
        },
        production() {
            let data = {

                iot_id: this.iot_id,
                start_date: this.start_date,
                end_date: this.end_date
            };
            console.log(data);
            let id = JSON.parse(getcompany()).id;
            let url = process.env.VUE_APP_APIHOST + '/production/export?iot_id=' + data.iot_id + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&company_id=' + id;
            console.log(url);
            window.location.href = url;

        }
    },
    mounted() {
        const end = new Date().getTime();
        const start = new Date().getTime() - 3600 * 1000 * 24 * 30;
        this.value2 = [parseTime(start, '{y}-{m}-{d}'), parseTime(end, '{y}-{m}-{d}')];
        this.getdeviceList();
        this.getreport();
    }
};
</script>
<style scoped lang="scss">
.one {
    &-time {
        text-align: center;
    }
}

.top {
    padding: 20px 40px;
    // height: 80px;
    background: #ffffff;
    // display: flex;
    // align-items: center;

    &-item {
        display: flex;
        align-items: center;
        margin-right: 50px;

        .title {
            margin-right: 10px;
            font-size: 18px;
            font-weight: 700;
        }

        .data {
            margin: 0 30px;
            padding: 10px 0;

            border-radius: 30px;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.hover {
                background: rgb(100, 181, 246);
            }
        }
    }
}

.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .top {
        padding: 10px;
    }
    .top-item {
        flex: 1;
    }
    .top-item .data {
        margin: 0 10px;
        width: auto;
        font-size: 12px;
        padding: 5px;
    }
}

.one-time {
    font-size: 16px;
    line-height: 24px;
}

.devList {
    width: 100%;
    margin-top: 20px;
}

.devListbox {
    display: inline-block;
    font-size: 14px;
    padding: 8px 10px;
    background: #209e91;
    color: white;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 10px;
}

.active {
    background: #e6a23c;
}
</style>
